<template lang="pug">
.tabs
  .tabs__grid
    ul.tabs__topNav(v-if="tabList.length")
      li.tabs__item(
        v-for="tab in tabList"
        :key="tab.name"
        :class="{ active: tab.name === selected }"
        @click="selectTab(tab.name)"
      )
        span.tabs__label
          | {{ formatTabLabel(tab.name) }}
          span(v-if="tab.count") ({{ tab.count }})
    .tabs__main
      slot(v-if="selectedSlot" :name="selectedSlot")
      slot(v-else name="main")
    .tabs__sidebar(v-if="slots.sidebar")
      slot(name="sidebar")
</template>

<script setup>
import { ref, computed, useSlots, defineEmits } from 'vue';

const emit = defineEmits(['tab-click']);
const slots = useSlots();
const selected = ref(null);

// Extract only slot names prefixed with "tab-"
const tabList = computed(() =>
  Object.keys(slots)
    .filter((name) => name.startsWith('tab-'))
    .map((name) => ({ name })),
);

// Automatically select the first tab if none is selected
const selectedSlot = computed(() => selected.value);

const selectTab = (name) => {
  selected.value = name;
  emit('tab-click', name);
};

// Ensure the first tab is selected on mount
onMounted(() => {
  if (!selected.value && tabList.value.length) {
    selected.value = tabList.value[0].name;
  }
});

// Convert "tab-most-viewed" -> "Most Viewed"
const formatTabLabel = (name) => {
  return name
    .replace(/^tab-/, '') // Remove "tab-" prefix
    .replace(/-/g, ' ') // Replace hyphens with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize words
};
</script>
<style lang="scss" scoped>
$block: '.tabs';

#{$block} {
  $block: &;

  &#{$block} {
    width: 100%;
  }

  #{$block}__grid {
    display: grid;
    column-gap: 0;
    row-gap: 2rem;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 0px 1fr 0px;
    grid-template-areas:
      '. topNav .'
      '. main .'
      '. sidebar .';

    @include media-breakpoint-up(sm) {
      column-gap: 0.5rem;
      grid-template-columns: calc(50vw - 17.1rem) 1fr calc(50vw - 17.1rem);
    }

    @include media-breakpoint-up(md) {
      column-gap: 2em;
      row-gap: 3rem;
      grid-template-columns: calc(50vw - 22rem) 1fr calc(50vw - 22rem);
    }

    @include media-breakpoint-up(lg) {
      row-gap: 4rem;
      grid-template-areas:
        '. topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav .'
        '. main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar .';
      grid-template-rows: repeat(2, auto);
      grid-template-columns:
        calc(50vw - 30rem)
        repeat(24, 1fr)
        calc(50vw - 30rem);
    }

    @include media-breakpoint-up(xl) {
      row-gap: 5rem;
      grid-template-areas:
        '. topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav topNav .'
        ' . main main main main main main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar . ';
      column-gap: 1rem;
      grid-template-columns:
        calc(50vw - 36rem) repeat(36, 1fr)
        calc(50vw - 36rem);
    }

    @include media-breakpoint-up(xxl) {
      row-gap: 6rem;
      grid-template-columns: calc(50vw - 40rem) repeat(36, 1fr) calc(
          50vw - 40rem
        );
    }
  }

  #{$block}__topNav {
    grid-area: topNav;
    overflow: hidden;
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
    border-bottom: 2px solid #8c78aa;
    color: #bca9da;
    font-weight: bold;
  }

  #{$block}__main {
    grid-area: main;
  }

  #{$block}__sidebar {
    grid-area: sidebar;
  }

  #{$block}__label {
    white-space: nowrap;
  }

  #{$block}__item {
    cursor: pointer;
    border-radius: 4px;
    transition: color 0.3s ease;
    padding: 0.5rem;

    @include media-breakpoint-up(lg) {
      padding: 0.5rem 1rem;
    }

    &:hover,
    &--active {
      color: white;
    }
  }
}
</style>
