<template lang="pug">
#read.scrollIntoView
  EcreativMoleculesTitleSection.mb-5(
    title="Read"
    subTitle="Dive into Islamic Wisdom and Knowledge"
  )
  EcreativMoleculesTabsAndSidebar(@tab-click="setSelected")
    template(#tab-articles)
      .articles-grid
        EcreativMoleculesCardsCard7.item(
          v-for='item in articles'
          :id='item.id',
          :key='item.id',
          :image='item.image',
          :title='item.title',
          :content="''",
          :truncateContent="''",
          :link='item.slug',
          linkLabel="Read More",
          category='articles',
          :date='item.date'
        )
        .load-more-container.pt-4
          EcreativMoleculesButtonsLoadMore.mb-0(
            v-if="!articlesStore.hideLoadMore",
            :isLoading="articlesStore.isLoading"
          )
    template(#tab-books)
      .books-grid
        EcreativMoleculesCardsCard3(
          image="/images/books/The-Advent-of-the-Promised.png"
          link="https://files.alislam.cloud/pdf/The-Advent-of-The-Promised-Messiah.pdf"
          title="The Advent of the Promised Messiah"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/True-Justice-and-Peace.png"
          link="https://files.alislam.cloud/pdf/True-Justice-and-Peace.pdf"
          title="True Justice and Peace"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/Islam-and-Europe.png"
          link="https://files.alislam.cloud/pdf/The-Advent-of-The-Promised-Messiah.pdf"
          title="The Advent of the Promised Mv"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/The-Truth-Unveiled.png"
          link="https://files.alislam.cloud/pdf/The-Truth-Unveiled.pdf"
          title="The Truth Unveiled"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/Our-God.png"
          link="https://files.alislam.cloud/pdf/Our-God.pdf"
          title="Our God"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/The-Philosophy-of-the.png"
          link="https://files.alislam.cloud/pdf/Philosophy-of-Teachings-of-Islam.pdf"
          title="The Philosophy of the Teaching of Islam"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/Hallmarks-of-the-Saints.png"
          link="https://files.alislam.cloud/pdf/Hallmarks-of-the-Saints.pdf"
          title="Hallmarks of the Saints"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/A-message-of-peace.png"
          link="https://files.alislam.cloud/pdf/Message-of-Peace.pdf"
          title="A Message of Peace"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/An-Elementary-Study-of-Islam.png"
          link="https://files.alislam.cloud/pdf/Elementary-Study-of-Islam.pdf"
          title="An Elementary Study of Islam"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/World-Crisis-and-the-Pathway.png"
          link="https://files.alislam.cloud/pdf/World-Crisis-and-Pathway-to-Peace.pdf"
          title="World Crisis and the Pathway"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/A-Misconception-Removed.png"
          link="https://files.alislam.cloud/pdf/A-Misconception-Removed.pdf"
          title="A Misconception Removed"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/Life-of-Muhammad.png"
          link="https://files.alislam.cloud/pdf/Life-of-Muhammad.pdf"
          title="Life of Muhammad"
          subTitle="Mirza Tahir Ahmad"
        )
        EcreativMoleculesCardsCard3(
          image="/images/books/Some-Distinctive-Features.png"
          link="https://files.alislam.cloud/pdf/Some-Distinctive-Features-of-Islam.pdf"
          title="Some Distinctive Features"
          subTitle="Mirza Tahir Ahmad"
        )
    template(#tab-arguments)
      .arguments-grid.h-100
        .argument-item.bg-white.p-5.mb-5.rounded-4.h-100
          EcreativAtomsHeading.modalTitle(tag="h3") Arguments
          iframe.h-100.idIframe(src='https://trueislam.co.uk/argumentsPublic/', width="100%")
    template(#sidebar)
      EcreativMoleculesSidebar(
        :links="links"
        title="Our Beliefs"
        background="transparent"
      )
</template>

<script setup>
import { ref } from 'vue';

const articlesStore = useArticlesStore();

defineProps({
  articles: {
    type: Array,
    required: true,
  },
});

const tabs = ref([
  { label: 'Articles' },
  { label: 'Books' },
  { label: 'Arguments' },
]);

const selected = ref('Articles');

const setSelected = (tab) => {
  selected.value = tab;
};

const links = [
  {
    navTitle: 'Allah',
    dataLink: [
      {
        name: 'Who is God?',
        label: 'Who is God?',
        text: 'Who is God?',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'How Can We Know God?',
        label: 'How Can We Know God?',
        text: 'How Can We Know God?',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Prophets',
    dataLink: [
      {
        name: 'The Prophet Muhammad',
        label: 'The Prophet Muhammad',
        text: 'The Prophet Muhammad',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'The Promised Messiah',
        label: 'The Promised Messiah',
        text: 'The Promised Messiah',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Other Prophets',
        label: 'Other Prophets',
        text: 'Other Prophets',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Islam',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Further Learning',
        label: 'Further Learning',
        text: 'Further Learning',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Articles of Faith',
        label: 'Articles of Faith',
        text: 'Articles of Faith',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Pillars of Faith',
        label: 'Pillars of Faith',
        text: 'Pillars of Faith',
        link: '',
        type: 'IsInternal',
      },
      {
        name: "The Qur'an",
        label: "The Qur'an",
        text: "The Qur'an",
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Ahmadiyya',
    dataLink: [
      {
        name: 'Overview',
        label: 'Overview',
        text: 'Overview',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Promised Messiah in His Own Words',
        label: 'Promised Messiah in His Own Words',
        text: 'Promised Messiah in His Own Words',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Beliefs: In-Depth',
        label: 'Beliefs: In-Depth',
        text: 'Beliefs: In-Depth',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Khilafat',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Types of Khilafat',
        label: 'Types of Khilafat',
        text: 'Types of Khilafat',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Rashidun Khilafat',
        label: 'Rashidun Khilafat',
        text: 'Rashidun Khilafat',
        link: '',
        type: 'IsInternal',
      },
      {
        name: 'Ahmadiyya Khilafat',
        label: 'Ahmadiyya Khilafat',
        text: 'Ahmadiyya Khilafat',
        link: '',
        type: 'IsInternal',
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.articles-grid {
  display: grid;
  gap: 1rem;
  grid-auto-rows: auto; // Automatically adjust row height
  width: 100%;

  // Mobile first approach
  grid-template-columns: 1fr; // Single column for smallest screens

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr); // 2 columns for small screens
  }

  @include media-breakpoint-up(md) {
    gap: 1.25rem;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr); // 3 columns for large screens
  }

  .load-more-container {
    grid-column: 1 / -1; // Span the button across all columns
    display: flex;
    justify-content: center; // Center the button horizontally
    margin-top: 1rem; // Add spacing from the grid items
  }
}

.books-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.arguments-grid {
  display: grid;
  gap: 1rem; // Space around the grid item
  grid-template-columns: 1fr; // Single column layout
  grid-auto-rows: auto; // Automatically adjust the row height

  .argument-item {
    grid-column: span 1; // Occupies the entire column
    width: 100%; // Ensures full width
    position: relative;
    overflow: hidden;
  }

  // Responsive adjustments (if needed)
  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Same layout for smaller screens
  }
}
</style>
