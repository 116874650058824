<template lang="pug">
.articleCard(
  :class="positionClass"
  role="button"
  tabindex="0"
  @click="navigateToArticle"
  @keypress.enter="navigateToArticle"
)
  nuxt-img.articleCard__image(
    v-if="image"
    :src="image"
    :alt="`${title || ''} ${subTitle || ''}`"
    loading="lazy"
    fit="cover"
    width="100%"
    height="100%"
  )
  .articleCard__info.gap-2.d-flex.flex-column
    template(v-if="title || subTitle")
      EcreativAtomsHeading.mb-0(
        v-if="title"
        tag="h3"
        size="2"
        fontWeight="bold"
        :context="textColor"
      ) {{ title }}
      EcreativAtomsHeading.mb-0(
        v-if="subTitle"
        tag="h3"
        size="4"
        fontWeight="bold"
        :context="textColor"
      ) {{ subTitle }}
      EcreativAtomsParagraph(
        v-if="text"
        tag="h4"
        :context="textColor"
      ) {{ text }}
    .articleCard__link(v-if="linkText")
      EcreativAtomsLink(
        buttonType="Link"
        linkType="IsInternal"
        :linkColor="linkColor"
        fontWeight="bold"
        :text="linkText"
        underline="underline"
      )

    .articleCard__button(v-if="buttonText")
      EcreativAtomsButton(
        buttonType="Link"
        linkType="IsInternal"
        :link="getUrl"
        :context="buttonContext"
        :textColor="buttonTextColor"
        :text="buttonText"
        class="fw-bold justify-content-center"
        size="lg"
      )
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import EcreativAtomsHeading from '../../atoms/Heading.vue';
import EcreativAtomsButton from '../../atoms/Button.vue';
import EcreativAtomsParagraph from '../../atoms/Paragraph.vue';

// Define props with defaults
const props = withDefaults(
  defineProps<{
    image?: string;
    title?: string;
    subTitle?: string;
    text?: string;
    link?: string;
    category?: string;
    buttonText?: string;
    buttonContext?: string;
    buttonTextColor?: string;
    textColor?: string;
    position?: number;
    linkText?: string;
    linkColor?: string;
  }>(),
  {
    position: 1, // Default position
  },
);

const router = useRouter();

const getUrl = computed((): string => {
  if (!props.link) return '/';
  return props.category
    ? `/${props.category}/${props.link}`.replace('//', '/')
    : `/${props.link}`;
});

const navigateToArticle = (): void => {
  if (getUrl.value) router.push(getUrl.value);
};

// Apply the modifier to the parent instead of `articleCard__info`
const positionClass = computed(() => `articleCard--${props.position}`);
</script>

<style lang="scss" scoped>
$block: '.articleCard';

#{$block} {
  $block: &;

  &#{$block} {
    // aspect-ratio: 16/9;
    background-color: $blue-100;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    cursor: pointer;
    border-radius: 1rem;
    container-type: inline-size; /* Define this as a container for container queries */
    container-name: card;

    aspect-ratio: 16/12;

    @include media-breakpoint-up(sm) {
      aspect-ratio: unset;
    }

    &:focus {
      outline: 2px solid var(--ti-primary);
      outline-offset: 2px;
    }
  }

  #{$block}__button {
    @supports (container-type: inline-size) {
      max-width: 80%;

      @container card (min-width: 200px) {
        max-width: 32ch;
      }

      @container card (min-width: 400px) {
        max-width: 24ch;
      }
    }
  }

  #{$block}__info {
    max-width: 25ch; /* Fallback for browsers that don't support container queries */
    position: absolute;

    @supports (container-type: inline-size) {
      max-width: 80%; /* Default for very small containers */

      @container card (min-width: 375px) {
        max-width: 24ch;
      }

      @container card (min-width: 400px) {
        max-width: 28ch;
      }

      @container card (min-width: 500px) {
        max-width: 40ch;
      }

      @container card (min-width: 600px) {
        max-width: 48ch;
      }
    }
  }
}

// Modifiers
#{$block} {
  $block: &;

  @each $num,
    $pos
      in (
        1: (
            left: 32px,
            top: 24px,
          ),
        2: (
            right: 32px,
            top: 24px,
          ),
        3: (
            left: 32px,
            bottom: 24px,
          ),
        4: (
            right: 32px,
            bottom: 24px,
          )
      )
  {
    &#{$block}--#{$num} {
      #{$block}__info {
        @each $prop, $value in $pos {
          #{$prop}: $value;
        }
      }
    }
  }
}
</style>
