<script setup lang="ts">
import { ref } from 'vue';

const conditions = ref([
  {
    id: 'I',
    text: `That he/she shall abstain from Shirk (association of any partner with God) right up to the day of his/her death.`,
  },
  {
    id: 'II',
    text: `That he/she shall keep away from falsehood, fornication, adultery, trespasses of the eye, debauchery,
      dissipation, cruelty, dishonesty, mischief and rebellion; and will not permit himself/herself to be
      carried away by passions, however strong they might be.`,
  },
  {
    id: 'III',
    text: `That he/she shall regularly offer the five daily prayers in accordance with the commandments of God and
      the Holy Prophet (peace and blessings of Allah be upon him); and shall try his/her best to be regular in
      offering the Tahajud (pre-dawn supererogatory prayers) and invoking Darood (blessings) on the Holy Prophet
      (peace and blessings of Allah be upon him); that he/she shall make it his/her daily routine to ask
      forgiveness for his/her sins, to remember the bounties of God and to praise and glorify Him.`,
  },
  {
    id: 'IV',
    text: `That under the impulse of any passions, he/she shall cause no harm whatsoever to the creatures of Allah
      in general, and Muslims in particular, neither by his/her tongue nor by his/her hands nor by any other means.`,
  },
  {
    id: 'V',
    text: `That he/she shall remain faithful to God in all circumstances of life, in sorrow and happiness, adversity
      and prosperity, in felicity and trial; and shall in all conditions remain resigned to the decree of Allah and
      keep himself/herself ready to face all kinds of indignities and sufferings in His way and shall never turn away
      from it at the onslaught of any misfortune; on the contrary, he/she shall march forward.`,
  },
  {
    id: 'VI',
    text: `That he/she shall refrain from following un-Islamic customs and lustful inclinations, and shall completely
      submit himself/herself to the authority of the Holy Quran; and shall make the Word of God and the Sayings of
      the Holy Prophet (peace and blessings of Allah be upon him) the guiding principle in every walk of his/her life.`,
  },
  {
    id: 'VII',
    text: `That he/she shall entirely give up pride and vanity and shall pass all his/her life in humbleness, cheerfulness,
      forbearance and meekness.`,
  },
  {
    id: 'VIII',
    text: `That he/she shall hold faith, the honor of faith, and the cause of Islam dearer to him/her than his/her life,
      wealth, honor, children and all other dear ones.`,
  },
  {
    id: 'IX',
    text: `That he/she shall keep himself/herself occupied in the service of God’s creatures for His sake only; and shall
      endeavor to benefit mankind to the best of his/her God-given abilities and powers.`,
  },
  {
    id: 'X',
    text: `That he/she shall enter into a bond of brotherhood with this humble servant of God, pledging obedience to me
      in everything good, for the sake of Allah, and remain faithful to it till the day of his/her death; that he/she
      shall exert such a high devotion in the observance of this bond as is not to be found in any other worldly
      relationship and connections demanding devoted dutifulness.`,
  },
]);
</script>

<template lang="pug">
section.container
  p
    | The Arabic term “bay’ah” (or “bai’at”) has been translated here as
    | “pledge” or “initiation”, but its true meaning is far more profound. The
    | root of bai’at means an exchange. The follower gives himself over in
    | obedience to the spiritual leader, in exchange for which the follower
    | obtains the nearness of God and His love. This exchange is referred to in
    | the Holy Qur’an in several passages:
  blockquote.blockquote
    p
      | "Verily, those who swear allegiance to thee, indeed, swear allegiance to
      | Allah. The hand of Allah is over their hands. So whoever breaks his
      | oath, breaks it to his own loss; and whoever fulfills the covenant that
      | he has made with Allah. He will, surely, give him a great reward."
      | (Qur’an 48:11)
  blockquote.blockquote
    p
      | "Surely, Allah was well-pleased with the believers when they were
      | swearing allegiance to thee under the Tree, and He knew what was in
      | their hearts, and He sent down tranquillity on them, and He rewarded
      | them with a victory at hand;" (Qur’an 48:19)
  p
    | In these verses, we learn that swearing allegiance to a prophet of God is
    | indeed swearing allegiance to God Himself. Thus, swearing allegiance to
    | the Khalifa (representative) of the prophet has the same connotation.
section
  h2 The Pre-Conditions of Bai’at
  p
    | The founder of the Ahmadiyya Muslim Community, the Promised Messiah and
    | Imam Mahdi عليه السلام, explaining its true significance, stated:
  blockquote.blockquote
    p
      | "Bai’at truly means to sell oneself; its blessings and impact are based
      | on that condition. Just as a seed is sown into the ground, its original
      | condition is that the hand of the farmer has sown it, but it is not
      | known what will happen to it...
    p
      | If the seed is of a good quality and possesses the capacity to grow,
      | then with the grace of Allah the Almighty, and as a consequence of the
      | work done by the farmer, it grows until one grain turns into a thousand
      | grains. Similarly, the person taking bai’at has to first adopt lowliness
      | and humility and has to distance himself from his ego and selfishness.
      | Then that person becomes fit for growth. But he who continues to hold on
      | to his ego, along with taking bai’at, will never receive any grace."
    p [Malfoozat, vol. 6, p. 173]
    section
  h1 Repentance is the Purpose of Bai’at
  p
    | The fundamental spiritual reality of the bai’at is to undergo repentance,
    | and to graft one’s own soul to the soul of the one appointed by God, so
    | that the blessings that flow in him should pass into oneself. Explaining
    | this, the Promised Messiah and Imam Mahdi عليه السلام states:
  blockquote.blockquote
    p
      | "...The outstanding aspect of swearing allegiance to a spiritual guide
      | is repentance (tawbah), which means to turn back (ruju). Repentance
      | refers to the state when man forsakes the ties that bind him to sin.
      | When a person is indulged in a life of sin, he begins to dwell in this
      | state and sin becomes his homeland, as it were. So, tawbah is to leave
      | one’s homeland and ruju means to purify oneself."
    p
      | It lies heavy on a person to leave their homeland and one is faced with
      | thousands of difficulties in doing so. There are a myriad of problems
      | that one is confronted with even when he leaves his home. But when it
      | comes to migrating from one’s native land, an individual must sever ties
      | with friends and loved ones, and bid farewell to everything, such as his
      | hearth and home, his neighbours, the streets and alleys of his prior
      | homeland, and its place of business. He is compelled to move to a new
      | country and he never returns to his homeland. This is called tawbah.
    p
      | The friends of evil are different from those who befriend righteousness.
      | The Sufis have named this transformation ‘death.’ A person who repents
      | is compelled to sustain a heavy loss. Indeed, true repentance demands
      | great sacrifices, but Allah the Exalted is Merciful and Munificent. God
      | does not let a person die until He bestows upon them a better substitute
      | for all…
    p
      | "This is the reality of repentance (which has been mentioned above). But
      | why is repentance an integral part of swearing allegiance to a spiritual
      | guide? The fact of the matter is that man is given to negligence. When
      | an individual takes the oath of allegiance at the hand of one upon whom
      | Allah the Exalted has bestowed this transformed state, the same divine
      | grace and light—that exists in this transformed individual—begins to
      | emerge in the individual just as a graft changes the properties of a
      | tree. The condition, however, is that one should possess a true
      | relationship with this person as though they were a branch attached to
      | his very being; not a lifeless branch. The greater a person’s affinity
      | with this spiritual leader, the better."
    p
      a(href='https://files.alislam.cloud/pdf/Malfuzat-1.pdf')
        | [Malfoozat, vol. 1, p.1 - 4]
section
  h1 Expectations of Ahmadi Muslims
  p
    | The founder of the Ahmadiyya Muslim Community, the Promised Messiah and
    | Imam Mahdi عليه السلام, explained what he expected of those who pledged
    | allegiance to him:
  blockquote.blockquote
    p
      | "The purpose of their joining the Movement and establishing with me a
      | relationship like that of a disciple is that they should achieve a high
      | degree of piety and righteousness. No wrongdoing or mischief should ever
      | come near them. They should offer the five daily Prayers regularly and
      | with congregation, and should not lie, nor hurt anyone by their
      | tongues."
    p
      | "They should not be guilty of any kind of vice and should not let even a
      | thought of mischief, wrongdoing or transgression pass through their
      | minds. They should shun every type of sin, offence, undesirable speech
      | and action, as well as all egoistic passions and unruly behaviour. They
      | should become pure-hearted, harmless and meek servants of God Almighty,
      | and no poisonous germ should flourish in their beings."
    p
      a(href='https://files.alislam.cloud/pdf/Essence-4.pdf')
        | [Essence of Islam, Vol. IV, pp. 349 – 250]
  section.conditions-container
    h1 Explore the Conditions of Bai’at
    p Below you will find the ten conditions of Bai’at.
    p
      | You can read about what each of these conditions mean, in a compilation
      | of speeches by Hazrat Mirza Masroor Ahmad, may Allah be his helper, 5th
      | Caliph of the Ahmadiyya Muslim Community,
      a(href='https://www.alislam.org/book/conditions-baiat-responsibilities-ahmadi/') here
      | .
    p
      | These conditions were outlined by Hazrat Mirza Ghulam Ahmad عليه السلام,
      | the Promised Messiah and Imam Mahdi, and we present them below, in his
      | words:
    h2 10 Conditions of Bai’at
    .conditions-list
      article.condition-card(v-for='condition in conditions' :key='condition.id')
        h3.condition-id {{ condition.id }}
        p.condition-text {{ condition.text }}
    p.translated-text (Translated from Ishtehar Takmeel-e-Tabligh, January 12, 1889)
  section
    h2 Want to Join?
    p
      | Joining the Ahmadiyya Muslim Community is not a trivial matter. It should only be undertaken after prayer,
      | reflection, and deliberation. One should reflect on the claims of the founder of the Ahmadiyya Muslim Community,
      | and whether one truly believes he is who he claimed to be.
    p
      | If you wish to join the community and be recognised as a member, please fill in the information below.
      | The process will be as follows:
    h3 Step 1:
    p
      | Fill in your information below to signify your interest and wish to join the Ahmadiyya Muslim Jama’at (Community) in Islam.
    h3 Step 2:
    p
      | A verification process will be initiated based on the information you provide below.
      | You may be contacted by office-holders of the community as part of that process.
    h3 Step 3:
    p
      | In case of a successful verification process, you will be invited to join the community formally,
      | through filling in an official application form that will be sent to the address you have provided below.
    h3 Step 4:
    p
      | Once your bai’at (initiation) form is received, you will be sent a welcome pack and a gift,
      | part of which includes free literature of the community.
  section
    h2 Important Points to Note
    ul
      li
        | The address you provide in the form below is the address we will use to contact you.
        | If you do not wish to receive letters to this address from the Ahmadiyya Muslim Community,
        | then please provide an address you are happy to receive post at.
      li
        | If the verification process in Step 3 concludes unsuccessfully, you will be contacted and informed
        | of why and what your next steps should be. This may involve reading more of the literature of the community,
        | and understanding better the claims of the founder, before being invited to join.
      li
        | Filling in the form below does not constitute or guarantee joining the community.
        | It represents an expression of interest and wish to join. Formal acceptance into the community will not be complete
        | until our receipt and processing of your formal application document that will be sent to you (see Steps 3 and 4 above).
        | Having read the ten conditions of Bai’at and having studied the claims of Hazrat Mirza Ghulam Ahmad عليه السلام I wish to join the Ahmadiyya Muslim Jama’at in Islam:
</template>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 20px;
}

h2 {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.conditions-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.condition-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  background: #f9f9f9;
}

.condition-id {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.condition-text {
  font-size: 1em;
  color: #555;
}

.translated-text {
  font-style: italic;
  text-align: center;
  margin-top: 30px;
}

.blockquote {
  background: #f0f0f0;
  padding: 15px;
  border-left: 5px solid #ccc;
  margin: 20px 0;
}
</style>
