<template lang="pug">
EcreativTemplatesMain2
  template(#introducing)
    EcreativOrganismsPresentingIntroAndCallToLearnMore2(:headingText="introData.headingText" :paragraphText="introData.paragraphText" :buttonText="introData.buttonText")
  template(#readSection)
    EcreativOrganismsPresentingReadResources(:articles="injectedArticles")
  template(#watchSection)
    EcreativOrganismsPresentingWatchResources(:articles="injectedArticles")
  template(#meetSection)
    EcreativOrganismsPresentingMeetResources
  template(#joinSection)
    EcreativOrganismsPresentingJoinResources
  template(#joinSection2)
    EcreativOrganismsPresentingJoin2Resources
  template(#latestArticles)
    EcreativOrganismsPresentingLatestArticlesAndCallToLoadMore(:articles="injectedArticles")
  template(#introV2)
    EcreativOrganismsExpandableMediaArticleV2(
      :title="messiahInfo.title"
      :caption="messiahInfo.caption"
      :content="messiahInfo.content"
      :imageSrc="messiahInfo.imageSrc"
      :imageAlt="messiahInfo.imageAlt"
      :introText="messiahInfo.introText"
      :learnMoreText="messiahInfo.learnMoreText"
    )
</template>

<script setup lang="ts">
import { ref, inject } from 'vue';

const { data: aboutSchema } = await useFetch('/api/about');
const { data: messiahInfo } = await useFetch('/api/messiah');
const { data: introData } = await useFetch('/api/intro');
const { data: sidebarSchema } = await useFetch('/api/sidebar');

const sliderArticles = inject('latestArticles', ref([]));
const elevenPoints = inject('elevenPoints', ref([]));
const injectedArticles = inject('articlesData', ref([]));

useHead({
  title: 'Introducing Ahmadiyyat',
});
</script>
