<template lang="pug">
.searchbox.d-flex.align-items-center
  form#form.inputbox.d-flex.align-items-center.gap-2(@submit.prevent="onSearch")
    label(for="query" class="visually-hidden") Search
    span.icon-container
      EcreativAtomsSvgIcon.icon(
        iconName="search",
        iconSize="md",
        isCustom
      )
    input.search.ps-2.p-2(
      type="text"
      id="query"
      v-model="query"
      placeholder="Search True Islam"
      aria-label="Search"
    )
  .pseudo-select-container(@click="toggleDropdown")
    .pseudo-select.d-flex.align-items-center(:class="{ open: isDropdownOpen }")
      span.selected-option {{ selectedTab }}
      span.icon
        EcreativAtomsSvgIcon(iconName="chevron-down", iconSize="sm")
    ul.pseudo-dropdown(v-if="isDropdownOpen")
      li.pseudo-option(
        v-for="tab in tabs"
        :key="tab"
        @click="selectTab(tab)"
      ) {{ tab }}
  .tabs
    ul.items.d-flex.m-0(role="tablist")
      li.item(
        v-for="tab in tabs"
        :key="tab"
        role="tab"
        :tabindex="selectedTab === tab ? 0 : -1"
        @click="selectTab(tab)"
        @keydown.space.prevent="selectTab(tab)"
        @keydown.enter.prevent="selectTab(tab)"
        :aria-selected="selectedTab === tab"
        :aria-controls="`panel-${tab}`"
      ) {{ tab }}
</template>

<script setup>
import { ref, watch } from 'vue';

// Reactive state
const query = ref('');
const tabs = ['All', 'Articles', 'Books', 'Q&A Library', 'Beliefs'];
const selectedTab = ref('Articles');
const isDropdownOpen = ref(false);

// Methods
const onSearch = () => {
  console.log(`Search for: ${query.value}`);
};

// Sync dropdown selection with tab selection
const selectTab = (tab) => {
  selectedTab.value = tab;
  isDropdownOpen.value = false;
  console.log(`Selected Tab: ${tab}`);
};

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

// Close dropdown when clicking outside
// document.addEventListener('click', (event) => {
//   if (!event.target.closest('.pseudo-select-container')) {
//     isDropdownOpen.value = false;
//   }
// });
</script>

<style lang="scss" scoped>
/* Mobile-first design */
.searchbox {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 55px;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

/* Input box */
.inputbox {
  flex: 1 1 25%;
  display: flex;
  align-items: center;
  min-height: 55px;
  border-right: 1px solid #cccccc;
  background-color: #f5f5f7;
  border-top-left-radius: 55px;
  border-bottom-left-radius: 55px;
  padding: 0 0 0 1rem;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  background-color: transparent;
  flex-grow: 1;
  font-size: 16px;
  outline: none;
  border: none;
  width: 100%;
}

/* Pseudo-select dropdown (Mobile) */
.pseudo-select-container {
  position: relative;
  min-width: 130px;
}

.pseudo-select {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-height: 55px;
  padding: 0 1rem;
  font-weight: 700;
  cursor: pointer;
  border-left: 1px solid #cccccc;
  border-top-right-radius: 55px;
  border-bottom-right-radius: 55px;
  background-color: white;
}

.pseudo-select .icon {
  transition: transform 0.2s ease-in-out;
}

.pseudo-select.open .icon {
  transform: rotate(180deg);
}

/* Dropdown menu */
.pseudo-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 8px 0;
  margin: 0;
}

.pseudo-option {
  padding: 10px 16px;
  cursor: pointer;
  font-weight: 700;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #f0f0f0;
  }
}

/* Desktop: Tabs */
.tabs {
  display: none; /* Hidden on mobile */
  align-items: center;
  width: auto;
  margin-left: auto;
  padding: 0 20px;
}

.items {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.item {
  cursor: pointer;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition:
    color 0.3s ease,
    background-color 0.3s ease;

  &:hover,
  &:focus {
    color: #cc3366;
  }

  &[aria-selected='true'] {
    color: #ffffff;
    background-color: #3e2073;
  }
}

/* Larger screens (Desktop) */
@include media-breakpoint-up(xl) {
  /* Hide pseudo dropdown and show tabs */
  .pseudo-select-container {
    display: none;
  }

  .tabs {
    display: flex; /* Show tabs on desktop */
  }
}
</style>
