<template lang="pug">
.initiating.text-white.d-flex.flex-column.gap-3.rounded-4
  nuxt-img.rounded-4(
    :src="image",
    :alt="`${title}`",
    fit="cover",
    loading="lazy",
    width="100%",
    height="auto",
  )
  .info.d-flex.flex-column
    EcreativAtomsHeading(
      tag="h3",
      context="white"
    ) {{ title }}
    EcreativAtomsHeading.mb-4(
      tag="h4",
      size="5",
      context="white"
    ) {{ subTitle }}
    .body-text.d-flex
      .d-flex.flex-column(v-for="(item, index) in props.items", :key="index")
        EcreativAtomsHeading.mb-3(
          tag="h5",
          size="6",
          context="white"
        ) {{ item.subTitle }}
        EcreativAtomsParagraph.text(
          context="white"
        ) {{ item.text }}
        EcreativAtomsLink(
          linkType="IsInternal",
          :link="item.link",
          linkColor="white",
          fontWeight="bold",
          text="Read More",
          underline="underline"
        )
</template>

<script setup>
import EcreativAtomsLink from '../atoms/Link.vue';
import EcreativAtomsHeading from '../atoms/Heading.vue';

const props = defineProps({
  image: {
    type: String,
  },
  title: {
    type: String,
  },
  subTitle: {
    type: String,
  },
  items: {
    type: Array,
    default: () => [],
  },
});
</script>

<style lang="scss" scoped>
.initiating {
  position: relative;
  height: 100%;

  aspect-ratio: 16/11;

  @include media-breakpoint-up(sm) {
    aspect-ratio: unset;
  }
}

.info {
  position: absolute;
  padding: 10px;
  max-width: max-content;

  @include media-breakpoint-up(sm) {
    top: 50px;
    left: 10px;
  }

  @include media-breakpoint-up(lg) {
    top: 50px;
    left: 50px;
  }

  @include media-breakpoint-up(xl) {
    top: 50px;
    left: 50px;
  }
}

.title {
  font-size: clamp(35px, 4vw, 45px);
  margin-bottom: 0;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0.7em;
  }
}

.subTitle {
  padding-bottom: 20px;
}

.subTitle,
.link {
  font-size: clamp(15px, 4vw, 20px);
}

.body-text {
  z-index: 2;
  gap: 10px;

  @include media-breakpoint-up(sm) {
    gap: 50px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 70%;
  }
}

.text {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.textBoxes {
  flex: 1;
}
</style>
