<template lang="pug">
#meet.scrollIntoView.resourcesGrid
  .resourcesGrid__top
    EcreativMoleculesTitleSection(
      title="Meet"
      subTitle="Connect with Local Imams for Guidance and Support"
    )
  .resourcesGrid__main
    .bodyMeet.d-flex.gap-3
      .map
        .map-box
          //- EcreativAtomsSvgIcon(iconName='ukMap' :isCustom='true', iconSize="full")
          img(src="/images/ukMap.svg", alt="Map")
  .resourcesGrid__sidebar
    EcreativMoleculesMeetSideBar
</template>

<script setup>
import { ref } from 'vue';

const articlesStore = useArticlesStore();

defineProps({
  articles: {
    type: Array,
  },
});

const videos = [
  {
    id: 1,
    image: '/images/home-3.jpg',
    title: 'The Importance of Bait in Islam',
    time: '47:15',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
  {
    id: 2,
    image: '/images/home-3.jpg',
    title: 'Muslim Youth Activities',
    time: '1:21:32',
  },
  {
    id: 3,
    image: '/images/home-3.jpg',
    title: 'Science & Islam',
    time: '21:09:43',
  },
];

const tabs = ref([
  { label: 'Featured' },
  { label: 'Latest' },
  { label: 'Most Viewed' },
]);

const selected = ref('Featured');

const setSelected = (tab) => {
  selected.value = tab;
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 0px 1fr 0px;
  grid-template-areas:
    '. top .'
    '. main .'
    '. sidebar .';

  @include media-breakpoint-up(sm) {
    grid-template-columns: calc(50vw - 17.1rem) 1fr calc(50vw - 17.1rem);
  }

  @include media-breakpoint-up(md) {
    row-gap: 3rem;
    grid-template-columns: calc(50vw - 22rem) 1fr calc(50vw - 22rem);
  }

  @include media-breakpoint-up(lg) {
    row-gap: 4rem;
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top .'
      '. main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar .';
    grid-template-rows: repeat(2, auto);
    grid-template-columns:
      calc(50vw - 30rem)
      repeat(24, 1fr)
      calc(50vw - 30rem);
  }

  @include media-breakpoint-up(xl) {
    row-gap: 5rem;
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top .'
      ' . main main main main main main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar . ';
    column-gap: 1rem;
    grid-template-columns:
      calc(50vw - 36rem) repeat(36, 1fr)
      calc(50vw - 36rem);
  }

  @include media-breakpoint-up(xxl) {
    row-gap: 6rem;
    grid-template-columns: calc(50vw - 40rem) repeat(36, 1fr) calc(50vw - 40rem);
  }
}

.grid__top {
  grid-area: top;
}

.grid__main {
  grid-area: main;
}

.grid__sidebar {
  grid-area: sidebar;
}

.tabs {
  width: 100%;
  border-bottom: 2px solid #8c78aa;

  &__nav {
    display: flex;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
    color: #bca9da;
    font-weight: bold;
  }

  .tab__item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: color 0.3s ease;

    &:hover,
    &.active {
      color: white;
    }
  }
}

.bodyMeet {
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}

.map {
  flex: 1;
}

.map-box {
  max-height: 80%;
}
</style>
