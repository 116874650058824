<template lang="pug">
div.card-body(@click="navigate")
  div.card-image
    nuxt-img(
      v-if="image",
      :src="image",
      :alt="`${title}`",
      loading="lazy",
      height="100%",
      width="100%"
    )
  div.card-info
    EcreativAtomsHeading.fs-7.mb-1(
      v-if="title",
      tag="h4",
      context="white",
      class="title"
    ) {{ title }}
    EcreativAtomsHeading.fs-7.mb-1(
      v-if="subTitle",
      class="subTitle",
      tag="h4",
      size="6"
    ) {{ subTitle }}
    time(
      v-if="time",
      class="text-white",
      :datetime="time"
    ) {{ time }}
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import EcreativAtomsHeading from '../../atoms/Heading.vue';

const router = useRouter();

const props = defineProps({
  image: {
    type: String,
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  time: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '#',
  },
});

const navigate = () => {
  if (props.link) {
    router.push(props.link);
  }
};
</script>

<style lang="scss" scoped>
.card-body {
  cursor: pointer;
  border-top-left-radius: $border-radius-sm;
  border-top-right-radius: $border-radius-sm;
}

.card-image {
  flex: 0 0 80%;
}

.card-info {
  padding-top: 10px;
  flex: 1;
  padding-top: 10px;
}

.subTitle {
  font-size: 0.8rem;
  color: hsl(0, 0%, 70%);
}
</style>
