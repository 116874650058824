<template lang="pug">
#join.scrollIntoView
  EcreativMoleculesTitleSection(
    title="Join"
    subTitle="Joining Ahmadiyya Islam: A Life-Changing Decision"
  )
  EcreativMoleculesTabsAndSidebar
    template(#main)
      .articlesGrid
        h2.fs-1.text-white
          | Every Step Forward is a Step Together
          span.d-block.fs-1.coloring in a journey of Unity, Growth, and Discovery
        EcreativMoleculesCardsCardSuper(
          image="/images/join/Block-one.jpeg"
          title="What is Bai’at?"
          text="Discover the meaning and profound spiritual significance of Bai’at as a pledge of allegiance in Islam."
          textColor="white"
          buttonContext="info"
          buttonText="Learn More"
        )
        EcreativMoleculesCardsCardSuper(
          image="/images/join/Block-two-A.jpeg"
          subTitle="Explore the conditions of Bai`at"
          linkColor="dark"
          textColor="dark"
          linkText="View Conditions"
          position="3"
        )
        EcreativMoleculesCardsCardSuper(
          image="/images/join/Block-two-B.jpeg"
          subTitle="Want to Join the Ahmadiyya Muslim Community?"
          linkColor="white"
          textColor="white"
          linkText="Learn More"
          position="1"
        )
        EcreativMoleculesCardsCardSuper(
          image="/images/join/Block-three.jpeg"
          title="Repentance: The Purpose of Bai’at"
          text="Learn how Bai’at enables spiritual transformation and repentance, fostering a deeper connection with God."
          buttonContext="dark"
          textColor="white",
          buttonText="Read More"
        )
        EcreativMoleculesCardsCardSuper(
          subTitle="Expectations of Members"
          text="Understand the high standards of piety, humility, and service expected of members, and how to stay steadfast in prayers and good deeds."
          linkColor="dark"
          textColor="dark",
          linkText="Learn More"
        )
        EcreativMoleculesThreeColumInfo(
          image="/images/join/Block-six.jpeg"
          title="Initiating the Bai’at Process"
          subTitle="Step-by-step guide"
          :items="items"
        )
    template(#sidebar)
      EcreativMoleculesSidebar(
        title="Top Articles"
        background="transparent"
      )
        ul.items.p-0.list-unstyled
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail1.png"
              title="My Journey to Ahmadiyya Islam: A Personal Story"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail2.png"
              title="Life After Conversion:Changes, Challenges, and Rewards"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail3.png"
              title="The Turning Point: What Drew Me to Ahmadiyya Islam"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail4.png"
              title="Why Faith Matters: Exploring the Role of Belief in Our Lives"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail5.png"
              title="Questions to Consider Before Converting to Ahmadiyya Islam"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail6.png"
              title="The Impact of Faith: Personal Growth Stories from Converts"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail7.png"
              title="Exploring the Core Beliefs of Ahmadiyya Islam"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail8.png"
              title="From Sunni to Ahmadiyya Islam - Convert Story"
            )
          li.item
            EcreativMoleculesCardsCard6(
              image="/images/articles/join_newconvert_thumbmail9.png"
              title="Navigating Family Dynamics: When You're the Only Muslim in the Family"
            )
</template>

<script setup>
import { ref } from 'vue';

const articlesStore = useArticlesStore();

defineProps({
  articles: {
    type: Array,
  },
});

const tabs = ref([
  { label: 'Articles' },
  { label: 'Books' },
  { label: 'Arguments' },
]);

const selected = ref('Articles');

const setSelected = (tab) => {
  selected.value = tab;
};

const items = ref([
  {
    subTitle: 'Express Interest ',
    text: 'Submit the expression of interest form to begin your journey and signity your wish to join the Ahmadiyya Muslim Community.',
    link: '#',
  },
  {
    subTitle: 'Verification',
    text: 'Community representatives will review your details and may contact you to provide guidance and ensure you fully understand the process.',
    link: '#',
  },
  {
    subTitle: 'Complete Application',
    text: 'After verification, complete the formal Baliat application. Upon approval, you will receive a welcome pack with resources to support your integration into the community.',
    link: '#',
  },
]);

const faqs = ref([
  {
    text: 'What is the conversion process like?',
    link: '#',
  },
  {
    text: 'What are the fundamental beliefs?',
    link: '#',
  },
  {
    text: 'What if my family has a different religious background?',
    link: '#',
  },
  {
    text: 'Are ther specific practices or rituals',
    link: '#',
  },
  {
    text: 'What changes should I expect in my daily life after converting?',
    link: '#',
  },
  {
    text: 'Can I still hold onto some beliefs or practices from my previous religion?',
    link: '#',
  },
]);

const links = [
  {
    navTitle: 'Allah',
    dataLink: [
      {
        name: 'Who is God?',
        label: 'Who is God?',
        text: 'Who is God?',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'How Can We Know God?',
        label: 'How Can We Know God?',
        text: 'How Can We Know God?',
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Prophets',
    dataLink: [
      {
        name: 'The Prophet Muhammad',
        label: 'The Prophet Muhammad',
        text: 'The Prophet Muhammad',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'The Promised Messiah',
        label: 'The Promised Messiah',
        text: 'The Promised Messiah',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Other Prophets',
        label: 'Other Prophets',
        text: 'Other Prophets',
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Islam',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Further Learning',
        label: 'Further Learning',
        text: 'Further Learning',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Articles of Faith',
        label: 'Articles of Faith',
        text: 'Articles of Faith',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Pillars of Faith',
        label: 'Pillars of Faith',
        text: 'Pillars of Faith',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: "The Qur'an",
        label: "The Qur'an",
        text: "The Qur'an",
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Ahmadiyya',
    dataLink: [
      {
        name: 'Overview',
        label: 'Overview',
        text: 'Overview',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Promised Messiah in His Own Words',
        label: 'Promised Messiah in His Own Words',
        text: 'Promised Messiah in His Own Words',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Beliefs: In-Depth',
        label: 'Beliefs: In-Depth',
        text: 'Beliefs: In-Depth',
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Khilafat',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Types of Khilafat',
        label: 'Types of Khilafat',
        text: 'Types of Khilafat',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Rashidun Khilafat',
        label: 'Rashidun Khilafat',
        text: 'Rashidun Khilafat',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Ahmadiyya Khilafat',
        label: 'Ahmadiyya Khilafat',
        text: 'Ahmadiyya Khilafat',
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.articlesGrid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr; // Default to a single column

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(
      2,
      1fr
    ); // Two-column layout from medium screens
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(
      6,
      1fr
    ); // Three-column layout for larger screens
  }

  h2 {
    grid-column: 1 / -1; // Span full width
  }

  & > :nth-child(2) {
    @include media-breakpoint-up(md) {
      grid-column: 1 / -1; // First card spans full width
    }
  }

  & > :nth-child(3),
  & > :nth-child(4) {
    @include media-breakpoint-up(md) {
      grid-column: span 3; // Next two cards in 50%/50% layout
    }
  }

  & > :nth-child(5) {
    @include media-breakpoint-up(md) {
      grid-column: span 4; // Fourth card takes 70% width
    }
  }

  & > :nth-child(6) {
    @include media-breakpoint-up(md) {
      grid-column: span 2; // Fifth card takes 30% width
    }
  }

  & > :nth-child(7) {
    @include media-breakpoint-up(md) {
      grid-column: 1 / -1; // Fifth card takes 30% width
    }
  }
}

.items {
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: column;
  }
}

.item {
  + .item {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 2px solid hsl(212, 100%, 79%, 0.5);
  }

  @include media-breakpoint-up(sm) {
    width: 33.3%;
  }

  @include media-breakpoint-up(xl) {
    width: unset;
  }
}

.second-row {
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.third-row {
  max-height: fit-content;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.right-section {
  display: flex;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-up(xl) {
    width: 25%;
  }
}

.coloring {
  color: #336699;
}
.item {
  flex: 1;
}
</style>
