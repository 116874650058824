<template lang="pug">
#join2.scrollIntoView.grid
  .grid__top
    EcreativMoleculesTitleSection(
      title="What is the Bai'at?"
      subTitle="What does Bai'at Mean?"
    )
  .grid__main.card.bg-white.p-4.rounded.shadow-sm
    EcreativBaiat
  .grid__sidebar
    EcreativMoleculesSidebar(
      title="EXPRESSION OF INTEREST"
      background="transparent"
    )
      .card.mb-4.text-white
        .card-body
          form(@submit.prevent='submitForm')

            // Introductory Statement
            .mb-3
              p Having read the ten conditions of Bai’at and having studied the claims of Hazrat Mirza Ghulam Ahmad عليه السلام, I wish to join the Ahmadiyya Muslim Jama’at in Islam.

            // Full Name
            .mb-3
              label.form-label Full Name
              input.form-control(v-model='form.fullName' type='text' placeholder='Enter your full name')

            // Sex Selection
            .mb-3
              label.form-label Sex
              .form-check
                input#male.form-check-input(v-model='form.sex' type='radio' value='Male')
                label.form-check-label(for='male') Male
              .form-check
                input#female.form-check-input(v-model='form.sex' type='radio' value='Female')
                label.form-check-label(for='female') Female

            // Age
            .mb-3
              label.form-label Age
              input.form-control(v-model='form.age' type='number' placeholder='Enter your age')

            // Address
            .mb-3
              label.form-label Address
              textarea.form-control(v-model='form.address' rows='3' placeholder='Enter your address')

            // Telephone Number Section
            .mb-3
              label.form-label Telephone Number
              input.form-control(v-model='form.phone' type='tel' placeholder='Enter your telephone number')

            hr

            // Family/Household Joining Section
            .mb-3
              label.form-label Are there others in your family or household who also wish to join? If so, indicate the total number of people joining, below. If it’s only you, leave it blank please.
              input.form-control(v-model='form.numberOfPeople' type='number' placeholder='Enter number of people joining')
            hr

            // GDPR & Privacy Policy Acknowledgement

            // Terms & Conditions Checkbox
            .mb-3
              input#terms.form-check-input(v-model='form.termsAccepted' type='checkbox')
              label.form-check-label(for='terms')
                .mb-3
                  p I recognise that completion of this form does not constitute nor guarantee entry into the Ahmadiyya Muslim Community, but is a first step in the process of joining.
                  p The address I have provided above can be used for correspondence purposes from the Ahmadiyya Muslim Community.
                  p We value your privacy and are committed to protecting your personal data in compliance with the General Data Protection Regulation (GDPR). By providing your information below:
                  ul
                    li You consent to us using your personal data for processing your admittance to the Ahmadiyya Muslim Community.
                    li Your data will be stored securely and will not be shared with third parties (outside our organisation) without your additional consent.
                    li You have the right to withdraw your consent at any time by contacting us at&nbsp;
                      a(href='mailto:tabligh@ahmadiyyauk.org') tabligh@ahmadiyyauk.org
                    li For more details on how we process your data and your rights under GDPR, please refer to our&nbsp;
                      a(href="https://ahmadiyya.uk/privacy-policy/") Privacy Policy.
            // Submit Button
            button.btn.btn-primary(type='submit') Submit
</template>

<script setup>
import { ref } from 'vue';

const articlesStore = useArticlesStore();

defineProps({
  articles: {
    type: Array,
  },
});

const form = ref({
  fullName: '',
  sex: '',
  age: '',
  address: '',
  phone: '',
  numberOfPeople: '',
  termsAccepted: false,
});

const submitForm = () => {
  if (!form.value.termsAccepted) {
    alert('You must accept the terms and conditions.');
    return;
  }
  console.log('Form submitted:', form.value);
};

const tabs = ref([
  { label: 'Articles' },
  { label: 'Books' },
  { label: 'Arguments' },
]);

const selected = ref('Articles');

const setSelected = (tab) => {
  selected.value = tab;
};

const items = ref([
  {
    subTitle: 'Express Interest ',
    text: 'Submit the expression of interest form to begin your journey and signity your wish to join the Ahmadiyya Muslim Community.',
    link: '#',
  },
  {
    subTitle: 'Verification',
    text: 'Community representatives will review your details and may contact you to provide guidance and ensure you fully understand the process.',
    link: '#',
  },
  {
    subTitle: 'Complete Application',
    text: 'After verification, complete the formal Baliat application. Upon approval, you will receive a welcome pack with resources to support your integration into the community.',
    link: '#',
  },
]);

const faqs = ref([
  {
    text: 'What is the conversion process like?',
    link: '#',
  },
  {
    text: 'What are the fundamental beliefs?',
    link: '#',
  },
  {
    text: 'What if my family has a different religious background?',
    link: '#',
  },
  {
    text: 'Are ther specific practices or rituals',
    link: '#',
  },
  {
    text: 'What changes should I expect in my daily life after converting?',
    link: '#',
  },
  {
    text: 'Can I still hold onto some beliefs or practices from my previous religion?',
    link: '#',
  },
]);

const links = [
  {
    navTitle: 'Allah',
    dataLink: [
      {
        name: 'Who is God?',
        label: 'Who is God?',
        text: 'Who is God?',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'How Can We Know God?',
        label: 'How Can We Know God?',
        text: 'How Can We Know God?',
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Prophets',
    dataLink: [
      {
        name: 'The Prophet Muhammad',
        label: 'The Prophet Muhammad',
        text: 'The Prophet Muhammad',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'The Promised Messiah',
        label: 'The Promised Messiah',
        text: 'The Promised Messiah',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Other Prophets',
        label: 'Other Prophets',
        text: 'Other Prophets',
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Islam',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Further Learning',
        label: 'Further Learning',
        text: 'Further Learning',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Articles of Faith',
        label: 'Articles of Faith',
        text: 'Articles of Faith',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Pillars of Faith',
        label: 'Pillars of Faith',
        text: 'Pillars of Faith',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: "The Qur'an",
        label: "The Qur'an",
        text: "The Qur'an",
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Ahmadiyya',
    dataLink: [
      {
        name: 'Overview',
        label: 'Overview',
        text: 'Overview',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Promised Messiah in His Own Words',
        label: 'Promised Messiah in His Own Words',
        text: 'Promised Messiah in His Own Words',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Beliefs: In-Depth',
        label: 'Beliefs: In-Depth',
        text: 'Beliefs: In-Depth',
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
  {
    navTitle: 'Khilafat',
    dataLink: [
      {
        name: 'Introduction',
        label: 'Introduction',
        text: 'Introduction',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Types of Khilafat',
        label: 'Types of Khilafat',
        text: 'Types of Khilafat',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Rashidun Khilafat',
        label: 'Rashidun Khilafat',
        text: 'Rashidun Khilafat',
        link: '#',
        type: 'IsInternal',
      },
      {
        name: 'Ahmadiyya Khilafat',
        label: 'Ahmadiyya Khilafat',
        text: 'Ahmadiyya Khilafat',
        link: '#',
        type: 'IsInternal',
      },
    ],
  },
];
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  column-gap: 1rem;
  row-gap: 2rem;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 0px 1fr 0px;
  grid-template-areas:
    '. top .'
    '. main .'
    '. sidebar .';

  @include media-breakpoint-up(sm) {
    grid-template-columns: calc(50vw - 17.1rem) 1fr calc(50vw - 17.1rem);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: calc(50vw - 22rem) 1fr calc(50vw - 22rem);
  }

  @include media-breakpoint-up(lg) {
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top .'
      '. main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar .';
    grid-template-rows: repeat(2, auto);
    grid-template-columns:
      calc(50vw - 30rem)
      repeat(24, 1fr)
      calc(50vw - 30rem);
  }

  @include media-breakpoint-up(xl) {
    grid-template-areas:
      '. top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top top .'
      ' . main main main main main main main main main main main main main main main main main main main main main main main main main sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar sidebar . ';
    row-gap: 3rem;
    column-gap: 1rem;
    grid-template-columns:
      calc(50vw - 36rem) repeat(36, 1fr)
      calc(50vw - 36rem);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: calc(50vw - 40rem) repeat(36, 1fr) calc(50vw - 40rem);
  }
}

.grid__top {
  grid-area: top;
}

.grid__main {
  grid-area: main;
}

.grid__sidebar {
  grid-area: sidebar;
}
</style>
