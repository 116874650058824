<template lang="pug">
#watch.scrollIntoView.gap-5.d-grid
  EcreativMoleculesTitleSection(
    title="Watch"
    subTitle="Exploring Islam through Multimedia"
  )
  EcreativMoleculesTabsAndSidebar(@tab-click="setSelected")
    template(#tab-featured)
      .videos-grid
        EcreativVideoPlayList4(
          :videoList="videoList"
          :youtubeApi="$youtubeApi"
        )
    template(#tab-latest)
      .videos-grid
        EcreativVideoPlayList4(
          :videoList="videoList5"
          :youtubeApi="$youtubeApi"
        )
    template(#tab-most-viewed)
      .videos-grid
        EcreativVideoPlayList4(
          :videoList="videoList6"
          :youtubeApi="$youtubeApi"
        )
    template(#sidebar)
      EcreativMoleculesSidebar(
        :title="'Top Videos'"
        :background="'transparent'"
      )
        EcreativVideoPlayList4(
          :videoList="videoList3"
          :youtubeApi="$youtubeApi"
        )
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

import { useNuxtApp } from '#imports';
const app = useNuxtApp();
const { $youtubeApi } = app;

const youtubeApiReady = ref(false);

onMounted(async () => {
  try {
    if (!$youtubeApi) {
      throw new Error('YouTube API plugin not found');
    }
    await $youtubeApi();
    youtubeApiReady.value = true;
    console.log('YouTube API is ready!');
  } catch (error) {
    console.error('Failed to load YouTube API:', error);
  }
});

const selected = ref('featured');

const setSelected = (tab) => {
  selected.value = tab;
};

// --- Types ---
interface Video {
  videoId: string;
  title: string;
  subTitle?: string;
  featured?: boolean;
}

const videoList5 = ref<Video[]>([
  {
    videoId: 'mQUZdansnS0',
    title:
      'Bible Vs Quran – David Wood Vs Muslims on Preservation / Authenticity / TRUTH',
    featured: true,
  },
  {
    videoId: 'wAEFDzlbifI',
    title: 'How a New York Banker Accepted Islam',
  },
  {
    videoId: '1j4OlXcQTYU',
    title:
      "Secret of 'Seal of Prophets' Muslims Forgot! Truth of Ahmad (SERIES) – Part 5",
  },
  {
    videoId: 'cuP-EzP6a-Y',
    title:
      'Prophecy in Bible, Hadith and QURAN Fulfilled! Truth of Ahmad (SERIES) – Part 6',
  },
  {
    videoId: 'XsQzbIK221o',
    title:
      'Message of Islam Ahmadiyya Examined: Truth of Ahmad (SERIES) – Part 7',
  },
  {
    videoId: 'FbVSPrKV7QI',
    title:
      'AHMADI VS. SUNNI DEBATE! Adnan Rashid REFUSES to Debate Quran/Hadith',
  },
  {
    videoId: 'olHtV_FKvoY',
    title: 'How God Deals with Opponents of Islam Ahmadiyya',
  },
  {
    videoId: 'X0UM-Y51B_I',
    title:
      'DEBATE | David Wood & Apostate Prophet VS Ahmadi Muslims – Was Muhammad a True Prophet?',
  },
  {
    videoId: 'ZaD_P8vv4To',
    title: 'Young Muslims REFUTE Christian & Atheist in Crucifixion Debate',
  },
  {
    videoId: 'lJo7Y709AR4',
    title:
      "Sunni Shaykh Abandons Quran – For JESUS! Ahmadi Muslim DEBATES Sunni | Speaker's Corner Episode 2",
  },
  {
    videoId: 'N_sIetr5gII',
    title: 'The Sign of the Imam Mahdi NO-ONE Can Make up!',
  },
  {
    videoId: 'SmIWdXqzHMA',
    title:
      'Christian vs Muslim DEBATE: Did Jesus Die by Crucifixion? Inspiring Philosophy vs True Islam UK',
  },
  {
    videoId: 'Xmm62jEyDPs',
    title: 'Did Muslims Copy Hajj from Jews?!',
  },
]);

const videoList6 = ref<Video[]>([
  {
    videoId: 'Xmm62jEyDPs',
    title: 'Did Muslims Copy Hajj from Jews?!',
    featured: true,
  },
  {
    videoId: 'cuP-EzP6a-Y',
    title:
      'Prophecy in Bible, Hadith and QURAN Fulfilled! Truth of Ahmad (SERIES) – Part 6',
  },
  {
    videoId: 'XsQzbIK221o',
    title:
      'Message of Islam Ahmadiyya Examined: Truth of Ahmad (SERIES) – Part 7',
  },
  {
    videoId: 'FbVSPrKV7QI',
    title:
      'AHMADI VS. SUNNI DEBATE! Adnan Rashid REFUSES to Debate Quran/Hadith',
  },
  {
    videoId: 'olHtV_FKvoY',
    title: 'How God Deals with Opponents of Islam Ahmadiyya',
  },
  {
    videoId: 'X0UM-Y51B_I',
    title:
      'DEBATE | David Wood & Apostate Prophet VS Ahmadi Muslims – Was Muhammad a True Prophet?',
  },
  {
    videoId: 'ZaD_P8vv4To',
    title: 'Young Muslims REFUTE Christian & Atheist in Crucifixion Debate',
  },
  {
    videoId: 'lJo7Y709AR4',
    title:
      "Sunni Shaykh Abandons Quran – For JESUS! Ahmadi Muslim DEBATES Sunni | Speaker's Corner Episode 2",
  },
  {
    videoId: 'N_sIetr5gII',
    title: 'The Sign of the Imam Mahdi NO-ONE Can Make up!',
  },
  {
    videoId: 'SmIWdXqzHMA',
    title:
      'Christian vs Muslim DEBATE: Did Jesus Die by Crucifixion? Inspiring Philosophy vs True Islam UK',
  },
  {
    videoId: 'mQUZdansnS0',
    title:
      'Bible Vs Quran – David Wood Vs Muslims on Preservation / Authenticity / TRUTH',
  },
  {
    videoId: 'wAEFDzlbifI',
    title: 'How a New York Banker Accepted Islam',
  },
  {
    videoId: '1j4OlXcQTYU',
    title:
      "Secret of 'Seal of Prophets' Muslims Forgot! Truth of Ahmad (SERIES) – Part 5",
  },
]);

const videoList3 = ref<Video[]>([
  {
    videoId: 'jekc6EvWjrw',
    title: "Survival and God's Protection: Truth of Ahmad (SERIES) – Part 1",
  },
  {
    videoId: 'oN6rOTtY070',
    title:
      '72 Sects Prove Ahmadiyya Islam TRUE: Truth of Ahmad (as) – PART 2 (SERIES)',
  },
  {
    videoId: '7swEFr7Q2a4',
    title:
      'Caliphate Proves Ahmadiyya Islam True: Truth of Ahmad (SERIES) – Part 3',
  },
  {
    videoId: 'T3zZVa2Pu6E',
    title:
      'Secrets of Surah al-Fatiha & Durood: Truth of Ahmad SERIES – Part 4',
  },
]);

const videoList = ref<Video[]>([
  {
    videoId: 'RUajlk-l5bI',
    title: 'Seven Irrefutable Proofs for Ahmadiyya Islam - FULL VIDEO',
    featured: true,
  },
  {
    videoId: '1j4OlXcQTYU',
    title:
      "Secret of 'Seal of Prophets' Muslims Forgot! Truth of Ahmad (SERIES) – Part 5",
  },
  {
    videoId: 'cuP-EzP6a-Y',
    title:
      'Prophecy in Bible, Hadith and QURAN Fulfilled! Truth of Ahmad (SERIES) – Part 6',
  },
  {
    videoId: 'XsQzbIK221o',
    title:
      'Message of Islam Ahmadiyya Examined: Truth of Ahmad (SERIES) – Part 7',
  },
  {
    videoId: 'FbVSPrKV7QI',
    title:
      'AHMADI VS. SUNNI DEBATE! Adnan Rashid REFUSES to Debate Quran/Hadith',
  },
  {
    videoId: 'olHtV_FKvoY',
    title: 'How God Deals with Opponents of Islam Ahmadiyya',
  },
  {
    videoId: 'X0UM-Y51B_I',
    title:
      'DEBATE | David Wood & Apostate Prophet VS Ahmadi Muslims – Was Muhammad a True Prophet?',
  },
  {
    videoId: 'ZaD_P8vv4To',
    title: 'Young Muslims REFUTE Christian & Atheist in Crucifixion Debate',
  },
  {
    videoId: 'lJo7Y709AR4',
    title:
      "Sunni Shaykh Abandons Quran – For JESUS! Ahmadi Muslim DEBATES Sunni | Speaker's Corner Episode 2",
  },
  {
    videoId: 'N_sIetr5gII',
    title: 'The Sign of the Imam Mahdi NO-ONE Can Make up!',
  },
  {
    videoId: 'SmIWdXqzHMA',
    title:
      'Christian vs Muslim DEBATE: Did Jesus Die by Crucifixion? Inspiring Philosophy vs True Islam UK',
  },
  {
    videoId: 'Xmm62jEyDPs',
    title: 'Did Muslims Copy Hajj from Jews?!',
  },
]);
</script>

<style lang="scss" scoped>
.videos-grid {
  display: grid;
  gap: 1rem;
  grid-auto-rows: auto;
  position: relative;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  :deep(.acople) {
    &.featured {
      height: 100%;

      @include media-breakpoint-up(md) {
        grid-column: span 2;
        grid-row: span 2;
      }
    }

    &:not(.featured) {
      height: 100%;
    }
  }
}

.featured {
  grid-column: span 2;
  grid-row: span 2;
}
</style>
