<template lang="pug">
.articleCard.articleCard--horizontal.d-flex
  .articleCard__image.rounded-4
    nuxt-img(
        v-if="image"
        :src="image"
        :alt="`${title} ${subTitle}`"
        format="png"
        loading="lazy"
        width="100%"
        height="100%"
    )
  .articleCard__info
    time.articleCard__time.text-black(:datetime="time") {{ formattedDate }}
    EcreativAtomsHeading(tag="h4" class="m-0 pt-1 display-7 fw-bold") {{ title }}
    EcreativAtomsHeading(tag="h4" class="fs-6 m-0 pb-1 fw-normal") {{ subTitle }}
    EcreativAtomsLink.articleCard__link(
      linkType="IsInternal"
      :link="getUrl"
      context="white"
      text="Read more"
      hoverColor="#0577e2"
    )
</template>

<script lang="ts" setup>
import { defineProps, computed } from 'vue';
import EcreativAtomsHeading from '../../atoms/Heading.vue';
import EcreativAtomsLink from '../../atoms/Link.vue';

const props = defineProps({
  image: String,
  title: { type: String, default: '' },
  subTitle: { type: String, default: '' },
  link: { type: String, default: '#' },
  category: { type: String, default: '' },
  time: { type: String, default: '2023-01-01' },
});

const getUrl = computed(() => {
  return props.category?.trim()
    ? `/${props.category}/${props.link}`.replace('//', '/')
    : `/${props.link}`;
});

const formattedDate = computed(() => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
  };
  return new Date(props.time).toLocaleDateString('en-US', options);
});
</script>
<style lang="scss" scoped>
$block: '.articleCard';

#{$block} {
  $block: &;

  &#{$block} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
  }

  #{$block}__image {
    img {
      height: auto;
      max-width: 150px;
      min-width: 100px;
    }
  }

  #{$block}__info {
    #{$block}__time,
    #{$block}__link {
      display: block;
      font-size: 0.875rem;
    }

    #{$block}__link {
      color: black;
      text-decoration: underline;

      &:hover {
        color: #0577e2;
      }
    }
  }
}

// Modifiers
#{$block} {
  $block: &;

  &#{$block}--horizontal {
    flex-direction: row;
    gap: 1rem;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }
}
</style>
