<template lang="pug">
EcreativMaskInnerContainer2(
  ref="playListContainer"
  :class="[{ featured: video.featured }, 'd-flex', 'flex-column']"
  v-for="(video, index) in videoList"
)
  EcreativAsyncVideoPlayer.w-100.h-100(
    :key="video.videoId"
    :videoId="video.videoId"
    :title="video.title"
    :subTitle="video.subTitle"
    :featured="video.featured"
    :loading="playerStates[video.videoId]?.loading"
    :error="playerStates[video.videoId]?.error"
    :isRounded="true"
    @update-last-player="(videoRef) => playerOnClick(videoRef)"
    @init-player="(videoRef) => initPlayer(video.videoId, videoRef)"
  )
    template(v-slot:metadata='')
      EcreativAtomsHeading.mb-1.pt-3(
      v-if="video.title",
      tag="h4",
      size="6",
      context="white"
      ) {{ video.title }}
      EcreativAtomsParagraph.mb-1.pt-3(
        v-if="video.subTitle",
        tag="h4",
        context="white",
        size="6"
      ) {{ video.subTitle }}
      time.text-white(
        v-if="video.time",
        :datetime="video.time"
      ) {{ video.time }}
    template(v-slot:overlay='')
      EcreativAtomsHeading.mb-auto(
        v-if="video.title",
        tag="h4",
        size="6",
        context="white"
      ) {{ video.title }}
    template(v-slot:button='')
      .playButton(role="button" aria-label="Play video")
        i.playButton__icon
          EcreativAtomsSvgIcon(
            iconName="playFill",
            iconSize="sm",
            isCustom
          )
</template>

<script setup lang="ts">
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  nextTick,
  withDefaults,
} from 'vue';
import type YT from '@types/youtube';

// --- Types ---
interface Props {
  videoList?: Video[];
  youtubeApi: () => Promise<typeof YT>;
}

interface Video {
  videoId: string;
  title: string;
  subTitle?: string;
  featured?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  videoList: () => [],
  youtubeApi: () => async () => {
    throw new Error('YouTube API not provided');
  },
});

interface PlayerStates {
  [key: string]: { loading: boolean; error: string | null };
}

// --- State ---
const apiLoading = ref(true);
const apiError = ref<string | null>(null);
const apiInitialized = ref(false);
const playListContainer = ref<HTMLElement | null>(null);
const initializedBackgrounds = reactive(new Map<string, boolean>());
const initializedPlayers = reactive(new Map<string, boolean>());
const players = reactive(new Map<string, HTMLElement>());
const playerStates = reactive<PlayerStates>({});
const lastPlayerChanged = ref<string | null>(null);

/**
 * Pauses the currently playing video.
 * @param {string} playerId - The ID of the player to pause.
 */
const pauseVideo = (playerId: string): void => {
  if (players.has(playerId)) {
    const playerElement = players.get(playerId);
    if (playerElement) {
      const ytInstance = playerElement.querySelector(
        'iframe',
      ) as HTMLIFrameElement;
      if (ytInstance && ytInstance.contentWindow) {
        ytInstance.contentWindow.postMessage(
          '{"event":"command","func":"pauseVideo","args":""}',
          '*',
        );
      }
    }
  }
};

/**
 * Ensures only one video plays at a time.
 * @param {string} newPlayerId - The ID of the newly playing video.
 */
const handleVideoChange = (newPlayerId: string): void => {
  if (lastPlayerChanged.value && lastPlayerChanged.value !== newPlayerId) {
    pauseVideo(lastPlayerChanged.value);
  }
  lastPlayerChanged.value = newPlayerId;
};

/**
 * Initializes the YouTube API if not already loaded.
 */
const initializeYouTubeApi = async (): Promise<void> => {
  if (apiInitialized.value) return;
  try {
    apiLoading.value = true;
    await props.youtubeApi();
    apiInitialized.value = true;
    apiError.value = null;
  } catch (error) {
    console.error('Failed to initialize YouTube API:', error);
    apiError.value = 'Failed to load YouTube player. Please refresh the page.';
  } finally {
    apiLoading.value = false;
  }
};

/**
 * Initializes video background thumbnail.
 * @param {string} videoId - The ID of the video.
 * @param {HTMLElement} videoRef - The reference to the video element.
 */
const initPlayer = async (videoId: string, videoRef: any): Promise<void> => {
  if (!videoRef || initializedBackgrounds.has(videoId)) return; // Avoid re-initializing background

  try {
    await initializeYouTubeApi();

    initializedBackgrounds.set(videoId, true);
    playerStates[videoId] = { loading: false, error: null };

    await nextTick();

    const img = videoRef.value.querySelector(
      '.videoPlayer__inner',
    ) as HTMLElement | null;
    if (img) {
      img.style.backgroundImage = `url(https://img.youtube.com/vi/${videoId}/maxresdefault.jpg)`;
    }
  } catch (error) {
    console.error('Error initializing player:', error);
    apiError.value = 'Failed to initialize video player';
  }
};

/**
 * Handles YouTube player ready event.
 * @param {YT.PlayerEvent} event - The YouTube player event.
 */
const onPlayerReady = (event: YT.PlayerEvent): void => {
  const playerId = event.target.getIframe().id;

  if (playerStates[playerId]) {
    playerStates[playerId].loading = false;
    playerStates[playerId].error = null;
  }
  event.target.playVideo();

  // ✅ Ensure only one video plays at a time
  handleVideoChange(playerId);
};

/**
 * Handles YouTube player state changes, ensuring only one video plays at a time.
 * @param {YT.OnStateChangeEvent} event - The state change event.
 */
const onPlayerStateChange = (event: YT.OnStateChangeEvent): void => {
  const playerId = event.target.getIframe().id;

  if (event.data === YT.PlayerState.PLAYING) {
    // ✅ Ensure only one video plays at a time
    handleVideoChange(playerId);
  }
};

/**
 * Handles YouTube player errors.
 * @param {YT.OnErrorEvent} event - The error event.
 */
const onPlayerError = (event: YT.OnErrorEvent): void => {
  const playerId = event.target.getIframe().id;
  if (playerStates[playerId]) {
    playerStates[playerId].error = 'An error occurred while playing the video.';
    playerStates[playerId].loading = false;
  }
};

/**
 * Handles the click event to embed a YouTube player and start the video.
 * @param {MouseEvent} event - The click event.
 */
const playerOnClick = async (videoRef: any): Promise<void> => {
  if (!props.youtubeApi || !videoRef) return;
  const YT = await props.youtubeApi();

  const inner = videoRef.value;
  const videoId = inner.dataset.embed;
  if (!inner || !videoId || initializedPlayers.has(videoId)) return;

  playerStates[videoId] = { loading: true, error: null };

  videoRef.value.classList.remove('videoPlayer--isOverlay');
  inner.innerHTML = '';

  const playerDiv = document.createElement('div');
  playerDiv.id = `${videoId}`;
  inner.appendChild(playerDiv);

  try {
    const ytPlayer = new YT.Player(playerDiv.id, {
      height: '100%',
      width: '100%',
      videoId,
      playerVars: { rel: 0 },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
        onError: onPlayerError,
      },
    });

    initializedPlayers.set(videoId, true);
    players.set(videoId, videoRef.value);

    // ✅ Ensure only one video plays at a time
    handleVideoChange(videoId);
  } catch (error) {
    console.error('Error creating YouTube player:', error);
    playerStates[videoId].loading = false;
    playerStates[videoId].error = 'Failed to initialize the video player.';
  }
};

// --- Lifecycle Hooks ---
onMounted(async () => {
  await nextTick();
  try {
    if (props.youtubeApi) {
      await props.youtubeApi();
    }
  } catch (error) {
    console.error('YouTube API failed to load:', error);
  }
});

onUnmounted(() => {
  Object.values(players).forEach((player) => {
    try {
      player.yt?.destroy();
    } catch (error) {
      console.error(`Error destroying player ${player.id}:`, error);
    }
  });
});
</script>

<style lang="scss" scoped>
$block: '.playButton';

#{$block} {
  &#{$block} {
  }

  #{$block}__icon {
    position: absolute;
    right: 30px;
    bottom: 15px;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 8px 0;
    border-radius: 74px;
    background-color: rgb(255, 255, 255);
    color: $primary;
    box-shadow:
      0 2px 2px 0 rgb(55 57 58 / 14%),
      0 3px 1px -2px rgb(52 68 70 / 20%),
      0 1px 5px 0 rgb(25 27 32 / 12%);
    font-size: 1.4rem;
    transition: all 0.2s ease-in;
  }

  &:hover {
    cursor: pointer;

    #{$block}__icon {
      background-color: rgb(255, 255, 255);
      color: $secondary;
      box-shadow:
        0 2px 2px 0 rgba(0, 188, 212, 0.14),
        0 3px 1px -2px rgba(0, 188, 212, 0.2),
        0 1px 5px 0 rgba(0, 188, 212, 0.12);
      fill: $primary;
      fill-opacity: 1;
    }
  }
}
</style>
