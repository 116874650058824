<template lang="pug">
.typewriter-container
  .line
    | The&nbsp;
    span.wrap.gradient-text {{ currentText }}
  | &nbsp;of Islam
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

interface Props {
  texts: string[];
  typingSpeed?: number;
  deleteSpeed?: number;
  pauseTime?: number;
}

const props = defineProps<Props>();
const currentText = ref('');
const loopNum = ref(0);
const isDeleting = ref(false);

const typingSpeed = props.typingSpeed || 400; // milliseconds
const deleteSpeed = props.deleteSpeed || 200; // milliseconds
const pauseTime = props.pauseTime || 3000; // milliseconds

const typeEffect = () => {
  const currentIndex = loopNum.value % props.texts.length;
  const fullText = props.texts[currentIndex];

  if (isDeleting.value) {
    currentText.value = fullText.substring(0, currentText.value.length - 1);
  } else {
    currentText.value = fullText.substring(0, currentText.value.length + 1);
  }

  let delay = typingSpeed;
  if (isDeleting.value) delay = deleteSpeed;

  if (!isDeleting.value && currentText.value === fullText) {
    delay = pauseTime;
    isDeleting.value = true;
  } else if (isDeleting.value && currentText.value === '') {
    isDeleting.value = false;
    loopNum.value++;
    delay = 500;
  }

  setTimeout(typeEffect, delay);
};

onMounted(() => {
  typeEffect();
});
</script>

<style lang="scss" scoped>
.wrap:empty::before {
  content: '\200B';
  display: inline-block;
}

.typewriter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wrap {
  position: relative;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
}

.wrap::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1em;
  width: 2px;
  background-color: #6a0dad;
  animation: blink 0.6s infinite alternate;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.gradient-text {
  background: linear-gradient(to right, #6a0dad, #c71585);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@include media-breakpoint-up(md) {
  .typewriter-container {
    flex-direction: row;
  }
  .line {
    flex-wrap: nowrap;
  }
}
</style>
